import React from "react";
import Image from "@common/Image/Image";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { MOBILE_MAX_WIDTH } from "@constants";
import OfficeOne1x from "@images/office/office1-1x.jpg";
import OfficeOne2x from "@images/office/office1-2x.jpg";
import OfficeOne3x from "@images/office/office1-3x.jpg";
import OfficeTwo1x from "@images/office/office2-1x.jpg";
import OfficeTwo2x from "@images/office/office2-2x.jpg";
import OfficeTwo3x from "@images/office/office2-3x.jpg";
import OfficeThree1x from "@images/office/office3-1x.jpg";
import OfficeThree2x from "@images/office/office3-2x.jpg";
import OfficeThree3x from "@images/office/office3-3x.jpg";
import OfficeFour1x from "@images/office/office4-1x.jpg";
import OfficeFour2x from "@images/office/office4-2x.jpg";
import OfficeFour3x from "@images/office/office4-3x.jpg";
import "./ImagesCollage.scss";

const ImagesCollage = () => {
    const { width } = useWindowDimensions();
    const isMobile = width <= MOBILE_MAX_WIDTH;

    return (
        <>
            {isMobile ? (
                <div className="vacancy-images-collage">
                    <div className="vacancy-images-collage__container">
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeOne1x}
                                srcSet={`${OfficeOne2x} 2x, ${OfficeOne3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit new office"
                            />
                        </div>
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeThree1x}
                                srcSet={`${OfficeThree2x} 2x, ${OfficeThree3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit hall"
                            />
                        </div>
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeTwo1x}
                                srcSet={`${OfficeTwo2x} 2x, ${OfficeTwo3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit small room"
                            />
                        </div>
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeFour1x}
                                srcSet={`${OfficeFour2x} 2x, ${OfficeFour3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit rest zone"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vacancy-images-collage">
                    <div className="vacancy-images-collage__column-left">
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeOne1x}
                                srcSet={`${OfficeOne2x} 2x, ${OfficeOne3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="Management Room"
                            />
                        </div>
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeThree1x}
                                srcSet={`${OfficeThree2x} 2x, ${OfficeThree3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="Dining Room"
                            />
                        </div>
                    </div>
                    <div className="vacancy-images-collage__column-right">
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeTwo1x}
                                srcSet={`${OfficeTwo2x} 2x, ${OfficeTwo3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit small room"
                            />
                        </div>
                        <div className="vacancy-images-collage__image-container">
                            <Image
                                src={OfficeFour1x}
                                srcSet={`${OfficeFour2x} 2x, ${OfficeFour3x} 3x`}
                                className="vacancy-images-collage__image"
                                alt="impressit rest zone"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImagesCollage;
