import React from "react";
import { Link, navigate } from "gatsby";
import { ReactSVG } from "react-svg";
import Image from "@common/Image/Image";
import aboutProject from "@images/vacancy/vacancy-about-project.svg";
import whatYouWillDo from "@images/vacancy/vacancy-what-you-will-do.svg";
import whatWeExpect from "@images/vacancy/vacancy-what-we-expect.svg";
import asAPlus from "@images/vacancy/vacancy-as-a-plus.svg";
import arrowBack from "@images/common/ic-arrow-back.svg";
import ImagesCollage from "./components/ImagesCollage/ImagesCollage";
import BenefitsSection from "../Careers/components/BenefitsSection/BenefitsSection";
import "./Vacancy.scss";
import SecondCatchUpSection from "../Common/CommonSections/SecondCatchUpSection/SecondCatchUpSection";

const Vacancy = ({ vacancy }) => {
    const applyVacancy = () =>
        navigate("/careers/contact-us-cv", {
            state: { vacancyId: vacancy.id },
        });
    return (
        <div className="vacancy-page">
            <Link to="/careers">
                <div className="vacancy-page__back-to-careers">
                    <ReactSVG
                        src={arrowBack}
                        className="vacancy-page__back-to-careers-icon"
                        alt="back to careers"
                    />
                    <span>Back to careers</span>
                </div>
            </Link>
            <h1>{vacancy.name}</h1>
            <div className="vacancy-page__vacancy-info">
                {vacancy.description && (
                    <div className="vacancy-page__info-section">
                        <div className="vacancy-page__info-section-title">
                            <Image src={aboutProject} alt="title image" />
                            <h2>About the project</h2>
                        </div>
                        <pre>{vacancy.description}</pre>
                    </div>
                )}
                <div className="vacancy-page__info-section-wrapper">
                    {vacancy.responsibility && (
                        <div className="vacancy-page__info-section">
                            <div className="vacancy-page__info-section-title">
                                <Image
                                    src={whatYouWillDo}
                                    alt="responsibility image"
                                />
                                <h2>What you will do</h2>
                            </div>
                            <pre>{vacancy.responsibility}</pre>
                        </div>
                    )}
                    {vacancy.demand && (
                        <div className="vacancy-page__info-section">
                            <div className="vacancy-page__info-section-title">
                                <Image src={whatWeExpect} alt="demand image" />
                                <h2>What we expect</h2>
                            </div>
                            <pre>{vacancy.demand}</pre>
                        </div>
                    )}
                </div>

                {vacancy.addition && (
                    <div className="vacancy-page__info-section">
                        <div className="vacancy-page__info-section-title">
                            <Image src={asAPlus} alt="addition image" />
                            <h2>As a plus</h2>
                        </div>

                        <pre>{vacancy.addition}</pre>
                    </div>
                )}
            </div>
            <div className="vacancy-page__catchup-section">
                <SecondCatchUpSection
                    isAnimated={false}
                    title="Ready to join the team?"
                    buttonText="Apply"
                    handleClick={applyVacancy}
                />
            </div>
            <BenefitsSection />
            <ImagesCollage />
            <p className="vacancy-page__bottom-description">
                Although working from home became the new normal, we still
                wanted our office to be both comfortable and beautiful. Our team
                enjoys convenient working places, stylish meeting rooms and
                spacious common area for after-work gatherings.
                <Link to="/office">About our office</Link>
            </p>
        </div>
    );
};

export default Vacancy;
